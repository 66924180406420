import { useCallback, useMemo } from 'react';
import { LobbyData } from '@common/games/Lobby/types';
import { RoomData } from '@common/games/Room/types';
import { Identifiers } from '@common/identifiers';
import { generateRandomDefaultUserStyle } from '@common/resources/avatars/generateRandomDefaultUserStyle';
import { GameNameIncludingLobby } from '@common/types/games';
import Player, { PlayerId, PlayerOrId } from '@common/types/player';
import { IState } from '@common/types/state';
import { UnboundUserStyle } from '@common/types/user';
import { Immutable } from '@common/utils';
import { AvatarRiveFileCache } from '@components/Avatars/AvatarRiveFileCache';
import { DrawerType } from '@components/SystemDrawer/Content';
import type { DiscordSDK } from '@discord/embedded-app-sdk';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { isEqual } from 'lodash';
import defaultPlayer from 'src/constants/default-player';
import { persistedAtom, selectAtomDeepEquals } from './utils';
import { getDecoration } from '@/constants/decorations';
import { environment, isDesktopMode, surface } from '@/environment';
import { useRoomData } from '@/hooks';
import { useUser } from '@/user';
import { RiveFileCache } from '@/utils/RiveFileCache';

const searchParams = new URL(window.location.href).searchParams;
const queryParams = Object.fromEntries(searchParams.entries());
export const queryParametersAtom = atom<Record<string, string>>(queryParams);
export const useQueryParameters = () => useAtomValue(queryParametersAtom);

export const isLoadingAnimationVisibleAtom = atom(true);

/*
 * gameState
 */
export const stateAtom = atom<Immutable<IState<RoomData>>>({
  scope: 'Room',
  data: {
    players: [],
    timer: {
      name: null,
      totalSeconds: 0,
      secondsRemaining: 0,
      progress: 0,
      rate: 1,
      isRunning: false,
    },
    isGameStarting: false,
    animationInProgress: false,
    hostPlayerId: null,
    roomSessionId: '',
    chat: [],
  },
  child: {
    scope: 'Lobby',
    data: { selectedGame: null, gameVotes: {} },
    child: null,
  } satisfies IState<LobbyData>,
} satisfies IState<RoomData>);
export const useGameState = () => useAtomValue(stateAtom);

/*
 * playerId
 */
export const playerIdAtom = persistedAtom<PlayerId>(
  'playerId',
  // When running in Discord, we use a temporary player ID until the user is
  // authenticated and we know their actual Discord user ID, which we then use
  // as the player ID.
  // We set this meaningless temp value to make it clear that the player ID is
  // not known until the user is authenticated.
  surface === 'discord' ? defaultPlayer.id : Identifiers.generatePlayerId(),
  {
    validateValueFromStorage: (playerIdFromLocalStorage) =>
      Identifiers.isValidWebPlayerId(playerIdFromLocalStorage),
  }
);
export const usePlayerId = () => useAtomValue(playerIdAtom);

export const anonymousUserStyleAtom = persistedAtom<UnboundUserStyle>(
  'anonymousUserStyle',
  generateRandomDefaultUserStyle()
);

/*
 * roomSessionId
 */
export const roomSessionIdAtom = selectAtomDeepEquals(
  stateAtom,
  ({ data }) => data.roomSessionId
);

const hostPlayerIdAtom = selectAtomDeepEquals(
  stateAtom,
  ({ data }) => data.hostPlayerId
);

export const useHostPlayerId = () => useAtomValue(hostPlayerIdAtom);

export const useHost = () => {
  const players = usePlayers();
  const hostPlayerId = useHostPlayerId();
  return players.find((p) => p.id === hostPlayerId);
};

export const isHostAtom = atom<boolean | null>((get) => {
  const hostPlayerId = get(hostPlayerIdAtom);
  const playerId = get(playerIdAtom);
  // If there is no host, such as before we've connected to the server, return null
  if (!hostPlayerId) return null;
  return hostPlayerId === playerId;
});

/*
 * player
 */
export const playerAtom = atom<Player>((get) => {
  const playerId = get(playerIdAtom);
  const allPlayers = get(playersAtom);
  const player = allPlayers.find((p) => p.id === playerId);
  return player ?? defaultPlayer;
});
export const usePlayer = () => useAtomValue(playerAtom);

const cosmeticAtom = selectAtomDeepEquals(
  playerAtom,
  (player) => player.cosmetic
);
export const useCosmetic = () => useAtomValue(cosmeticAtom);

export const avatarAtom = selectAtomDeepEquals(
  cosmeticAtom,
  (cosmetic) => cosmetic.avatar
);

export const useAvatar = () => useAtomValue(avatarAtom);

const playerNameAtom = selectAtomDeepEquals(
  playerAtom,
  (player) => player.name
);
export const usePlayerName = () => useAtomValue(playerNameAtom);

export const colorAtom = selectAtomDeepEquals(
  cosmeticAtom,
  (cosmetic) => cosmetic.color
);
export const useColor = () => useAtomValue(colorAtom);
export const useUserColor = () => {
  const color = useColor();
  return getDecoration(color);
};

/*
 * players
 */
export const playersAtom = selectAtomDeepEquals(
  stateAtom,
  ({ data }) => data.players
);

export const numPlayersAtom = atom((get) => get(playersAtom).length);

export const useNumPlayers = () => useAtomValue(numPlayersAtom);

export const usePlayers = () => useAtomValue(playersAtom);

export const usePlayerById = (id: PlayerId | null | undefined) => {
  const players = usePlayers();
  return players.find((p) => p.id === id);
};

export const usePlayerByPlayerOrId = (playerOrId: PlayerOrId) => {
  const players = usePlayers();
  if (typeof playerOrId === 'string') {
    const player = players.find((p) => p.id === playerOrId);
    return player;
  }
  return playerOrId;
};

export const usePlayersMeFirst = () => {
  const me = usePlayer();
  const others = usePlayers().filter((p) => p.id !== me.id);
  return [me, ...others].filter((p) => !!p);
};

export const accountCreationMiniModalAtom = atom(false);

export function useOpenAccountCreationMiniModal() {
  const setIsOpen = useSetAtom(accountCreationMiniModalAtom);
  return useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
}

/*
 * drawerType
 */
export const drawerTypeAtom = atom<DrawerType | null>(null);
export const useDrawerType = () => useAtomValue(drawerTypeAtom);

export function useOpenDrawer() {
  // Don't use useIsHost here, as it creates a circular dependency
  const setDrawerType = useSetAtom(drawerTypeAtom);
  return useCallback(
    (drawerType: DrawerType) => {
      setDrawerType(drawerType);
    },

    [setDrawerType]
  );
}

export function useCloseDrawer() {
  const setDrawerType = useSetAtom(drawerTypeAtom);
  return () => {
    setDrawerType(null);
  };
}

/**
 * SlapperContent
 */
const slapperContentAtom = atom<React.ReactNode>(null);
export const useSlapperContent = () => useAtomValue(slapperContentAtom);
export const useSetSlapperContent = () => useSetAtom(slapperContentAtom);

/*
 * currentGameName
 */
export const currentGameNameAtom = selectAtomDeepEquals(
  stateAtom,
  (state) => (state.child?.scope as GameNameIncludingLobby) ?? 'Lobby'
);

/**
 * Returns the current game name, defaulting to 'Lobby' if the gameName is null (e.g., before we've connected to the server).
 */
export const useCurrentGameName = () => useAtomValue(currentGameNameAtom);

export const discordSdkAtom = atom<DiscordSDK | undefined>(undefined);

// /**
//  * Atom to store the Discord access token. Should only be used in the 'discord' surface.
//  */
// export const discordAccessTokenAtom = atom<string | undefined>(undefined);

export const jwtAtom = persistedAtom<string | undefined>('jwt', undefined, {
  persistInitialValue: false,
});

// for systemDrawer resizing, we need to access the scaling ratio in different levels of components (e.g., AvatarPartHighlight.tsx)
export const drawerScaleFactorAtom = atom(1);
export const useDrawerScaleFactor = () => useAtomValue(drawerScaleFactorAtom);
export const useSetDrawerScaleFactor = () => useSetAtom(drawerScaleFactorAtom);

// for game window resizing, we need to access the scaling ratio in different levels of components (.e.g, mcmind animations)
export const desktopWindowScaleFactorAtom = atom(1);
export const useDesktopWindowScaleFactor = () =>
  useAtomValue(desktopWindowScaleFactorAtom);
export const useSetDesktopWindowScaleFactor = () =>
  useSetAtom(desktopWindowScaleFactorAtom);

/**
 * Audio settings
 */

function validateVolume(valueFromStorage: unknown): boolean {
  return (
    typeof valueFromStorage === 'number' &&
    valueFromStorage >= 0 &&
    valueFromStorage <= 1
  );
}

function validateBooleanValue(valueFromStorage: unknown): boolean {
  return typeof valueFromStorage === 'boolean';
}

export const soundEffectsVolumeAtom = persistedAtom(
  'soundEffectsVolumeAtom',
  0.1,
  { validateValueFromStorage: validateVolume }
);

export const useSoundEffectsVolume = () => useAtomValue(soundEffectsVolumeAtom);
export const useSetSoundEffectsVolume = () =>
  useSetAtom(soundEffectsVolumeAtom);

export const musicVolumeAtom = persistedAtom(
  'musicVolumeAtom',
  isDesktopMode ? 0.04 : 0.02, // If mobile, set default volume to 0.02 since it may be annoying for music to be playing from multiple phones in-person
  { validateValueFromStorage: validateVolume }
);
export const useMusicVolume = () => useAtomValue(musicVolumeAtom);
export const useSetMusicVolume = () => useSetAtom(musicVolumeAtom);

export const isSoundEffectsMuteAtom = persistedAtom(
  'isSoundEffectsMuteAtom',
  false,
  { validateValueFromStorage: validateBooleanValue }
);
export const useIsSoundEffectsMute = () => useAtomValue(isSoundEffectsMuteAtom);
export const useSetIsSoundEffectsMute = () =>
  useSetAtom(isSoundEffectsMuteAtom);

export const isMusicMuteAtom = persistedAtom('isMusicMuteAtom', false, {
  validateValueFromStorage: validateBooleanValue,
});
export const useIsMusicMute = () => useAtomValue(isMusicMuteAtom);
export const useSetIsMusicMute = () => useSetAtom(isMusicMuteAtom);

export const isShowingHowToPlayAtom = atom(false);
export const useIsShowingHowToPlay = () => useAtomValue(isShowingHowToPlayAtom);
export const useSetIsShowingHowToPlay = () =>
  useSetAtom(isShowingHowToPlayAtom);

export const isDiscordHardwareAccelerationEnabledAtom = atom(true);
export const useIsDiscordHardwareAccelerationEnabled = () =>
  useAtomValue(isDiscordHardwareAccelerationEnabledAtom);

export const authenticationFailureAtom = atom<unknown>(false);

export const discordSpeakingUsersAtom = atom<string[]>([]);

export const discordActivityInstanceParticipantUserIdsAtom = atom<string[]>([]);

export function usePlayersInDiscordActivityInstance() {
  const players = usePlayers();
  const discordActivityInstanceParticipantUserIds = useAtomValue(
    discordActivityInstanceParticipantUserIdsAtom
  );
  return players.filter((player) => {
    // Note: we assume that the player's ID is their Discord user ID
    // This should always be true for users playing on the discord surface,
    // which is the only place this hook is used.
    const playerDiscordId = player.id;
    return discordActivityInstanceParticipantUserIds.includes(playerDiscordId);
  });
}

/**
 * Hook to determine if a Discord user is currently speaking.
 *
 * This hook checks if the specified user is speaking on Discord. It will return
 * false on non-Discord platforms or if the user doesn't have permission to
 * access the target player's voice activity (e.g., they are muted).
 *
 * @param {PlayerOrId} playerOrId - The player or player ID to check.
 * @returns {boolean} - Returns true if the user is speaking, false otherwise.
 *
 * @example
 * const isSpeaking = useIsUserSpeaking(playerOrId);
 */
export const useIsUserSpeaking = (playerOrId: PlayerOrId): boolean => {
  const player = usePlayerByPlayerOrId(playerOrId);
  const speakingUsers = useAtomValue(discordSpeakingUsersAtom);
  if (!player) return false;
  // Note: we assume that the player's ID is their Discord user ID
  // This should always be true for users playing on the discord surface,
  // which is the only place this hook is used.
  const discordUserId = player.id;
  return speakingUsers.includes(discordUserId);
};

export const isBreadToasterWindowOpenAtom = atom(false);
export const useSetIsBreadToasterWindowOpen = () =>
  useSetAtom(isBreadToasterWindowOpenAtom);

export const useIsBreadToasterWindowOpen = () =>
  useAtomValue(isBreadToasterWindowOpenAtom);

export const avatarRefCountsAtomFamily = atomFamily((_playerId: PlayerId) => {
  return atom(0);
}, isEqual);

export const useAvatarRefCount = (playerId: PlayerId) => {
  const [refCount, setRefCount] = useAtom(avatarRefCountsAtomFamily(playerId));

  const incrementRefCount = () => {
    setRefCount((prevCount) => prevCount + 1);
  };

  const decrementRefCount = () => {
    setRefCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  return {
    refCount,
    incrementRefCount,
    decrementRefCount,
  };
};

export const isUserAuthenticatedAtom = atom(false);

export const useIsUserAuthenticated = () =>
  useAtomValue(isUserAuthenticatedAtom);

export const useIsDeveloper = () => {
  const { user } = useUser();
  return user?.isDeveloper || environment === 'Local';
};

export const useIsModerator = () => {
  const { user } = useUser();
  if (!user) return false;
  return user.isModerator || user.isDeveloper;
};

const isDiscordServerCoverSheetOpenAtom = atom(false);

export const useIsDiscordServerCoverSheetOpen = () =>
  useAtomValue(isDiscordServerCoverSheetOpenAtom);

export const useSetIsDiscordServerCoverSheetOpen = () =>
  useSetAtom(isDiscordServerCoverSheetOpenAtom);

export const mutedPlayersAtom = atom<PlayerId[]>([]);

export const useMutedPlayers = () => useAtomValue(mutedPlayersAtom);

export const useToggleMutedPlayer = () => {
  const setMutedPlayers = useSetAtom(mutedPlayersAtom);
  return (playerId: PlayerId) => {
    setMutedPlayers((prev) =>
      prev.includes(playerId)
        ? prev.filter((id) => id !== playerId)
        : [...prev, playerId]
    );
  };
};

export const useIsPlayerMuted = (playerId: PlayerId) => {
  const mutedPlayers = useMutedPlayers();
  return mutedPlayers.includes(playerId);
};

const isReportPlayerModalOpenAtom = atom(false);

export const useIsReportPlayerModalOpen = () =>
  useAtomValue(isReportPlayerModalOpenAtom);

export const useSetIsReportPlayerModalOpen = () =>
  useSetAtom(isReportPlayerModalOpenAtom);

export const useOpenReportPlayerModal = () => {
  const setIsReportPlayerModalOpen = useSetIsReportPlayerModalOpen();
  return () => {
    setIsReportPlayerModalOpen(true);
  };
};

export const useCloseReportPlayerModal = () => {
  const setIsReportPlayerModalOpen = useSetIsReportPlayerModalOpen();
  return () => {
    setIsReportPlayerModalOpen(false);
  };
};

const isPollModalOpenAtom = atom(false);

export const useIsPollModalOpen = () => useAtomValue(isPollModalOpenAtom);

export const useSetIsPollModalOpen = () => useSetAtom(isPollModalOpenAtom);

export const useOpenPollModal = () => {
  const setIsPollModalOpen = useSetIsPollModalOpen();
  return () => {
    setIsPollModalOpen(true);
  };
};

export const useClosePollModal = () => {
  const setIsPollModalOpen = useSetIsPollModalOpen();
  return () => {
    setIsPollModalOpen(false);
  };
};

export const inappropriateContentAtom = atom<
  | {
      content: string;
      reason: string;
    }
  | undefined
>(undefined);

const numUnreadMessagesAtom = atom(0);

export const useNumUnreadMessages = () => useAtomValue(numUnreadMessagesAtom);
export const useSetNumUnreadMessages = () => useSetAtom(numUnreadMessagesAtom);

export const useFilteredMessages = () => {
  const messages = useRoomData((data) => data.chat);
  const mutedPlayers = useMutedPlayers();

  return useMemo(() => {
    return messages.filter(
      (message) => !mutedPlayers.includes(message.playerId)
    );
  }, [messages, mutedPlayers]);
};

const isConnectionIssuesModalOpenAtom = atom(true);

export const useIsConnectionIssuesModalOpen = () =>
  useAtomValue(isConnectionIssuesModalOpenAtom);

export const useSetIsConnectionIssuesModalOpen = () =>
  useSetAtom(isConnectionIssuesModalOpenAtom);

/**
 * Atom containing the global instance of RiveFileCache.
 */
const riveFileCacheAtom = atom(new RiveFileCache());

export function useRiveFileCache() {
  return useAtomValue(riveFileCacheAtom);
}

const avatarRiveFileCacheAtom = atom(new AvatarRiveFileCache());

export function useAvatarRiveFileCache() {
  return useAtomValue(avatarRiveFileCacheAtom);
}
