import { GameMetaData } from '../types';
import Thumbnail from './assets/Thumbnail_Trio_BackdropAndTitle.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide2 from './assets/howto/Slide2.png';
import Slide3 from './assets/howto/Slide3.png';
import Slide4 from './assets/howto/Slide4.png';

export default {
  name: 'Rock-Tac-Toe',
  primaryAccentColor: 'Blue.Magic',
  secondaryAccentColor: 'Blue.Pastel',
  thumbnailImage: Thumbnail,
  taglines: ['Tactical', 'Competitive'],
  description: 'Play Tic-Tac-Toe with Rock Paper Scissors tiles.',
  elevatorPitch: "It's like Rock Paper Scissors and Tic-Tac-Toe had a baby!",
  minPlayers: 1,
  howToSlides: [
    {
      img: Slide1,
      imgAlt: "It's Rock Paper Scissors on a Tic-Tac-Toe grid",
      isFullScreenImage: true,
    },
    {
      img: Slide2,
      imgAlt: 'Earn a STAR by placing 3 tiles in a row',
      isFullScreenImage: true,
    },
    {
      img: Slide3,
      imgAlt: 'Battle opponents for grid spaces and break ties',
      isFullScreenImage: true,
    },
    {
      img: Slide4,
      imgAlt: '6+ stars (or highest at the end of 25 turns) = YOU WIN!',
      isFullScreenImage: true,
    },
  ],
  quickBits: [
    {
      emoji: '⏱️',
      text: '5 mins',
    },
    {
      emoji: '👥',
      text: '2+ players',
    },
    {
      emoji: '♟️',
      text: 'Tactical',
    },
  ],
} satisfies GameMetaData;
