import { Howl, HowlOptions } from 'howler';
import { getDefaultStore } from 'jotai';
import { Atom } from 'jotai';
import { clamp, debounce } from 'lodash';
import { MusicName } from './music/musicUrls';
import { SoundEffectsName } from './soundEffects/soundEffectsUrls';

type AudioName = MusicName | SoundEffectsName;

export type AudioOptions = {
  loop?: boolean;
  format?: HowlOptions['format'];
};

// Generic play function
export function playAudio(
  audioCache: Partial<Record<AudioName, Audio>>,
  urls: Partial<Record<AudioName, string>>,
  volumeAtom: Atom<number>,
  muteAtom: Atom<boolean>,
  name: AudioName,
  options?: AudioOptions
) {
  const { get } = getDefaultStore();
  const volume = get(volumeAtom);
  const isMute = get(muteAtom);
  let audio = audioCache[name];

  if (!audio) {
    const url = urls[name];
    if (!url) {
      console.error(`No audio found for ${name}`);
      return;
    }
    audio = createAudio(url, options);
    audioCache[name] = audio;
  }
  audio.setVolume(volume);
  audio.setIsMute(isMute);
  audio.play();
  return audio;
}

export function setVolume(
  audioCache: Partial<Record<AudioName, Audio>>,
  volumeAtom: Atom<number>,
  muteAtom: Atom<boolean>,
  name: AudioName
) {
  const { get } = getDefaultStore();
  const volume = get(volumeAtom);
  const isMute = get(muteAtom);
  const audio = audioCache[name];

  if (audio) {
    audio.setVolume(volume);
    audio.setIsMute(isMute);
  }
}

export function setAllVolume(
  audioCache: Partial<Record<AudioName, Audio>>,
  volumeAtom: Atom<number>,
  muteAtom: Atom<boolean>
) {
  const { get } = getDefaultStore();
  const volume = get(volumeAtom);
  const isMute = get(muteAtom);

  for (const audio of Object.values(audioCache)) {
    audio.setVolume(volume);
    audio.setIsMute(isMute);
  }
}

export function stopPlayingAll(audioCache: Partial<Record<AudioName, Audio>>) {
  for (const audio of Object.values(audioCache)) {
    audio.stopPlaying();
  }
}

export type Audio = {
  play: () => void;
  setVolume: (volume: number) => void;
  setIsMute: (isMute: boolean) => void;
  stopPlaying: () => void;
};

// Note(avi): I tried making a useSoundEffect() hook (now renamed to createAudio, as of 05/17/2024 -xxl), but that introduces some
// noticeable delay because creating the Howl object is expensive. So, it's
// better to create the Howl object once and reuse it, hence this function.

export function createAudio(
  sound: string,
  options: AudioOptions = { loop: false }
): Audio {
  const audio = new Howl({
    src: [sound],
    html5: false,
    mute: false,
    volume: 1,
    ...options,
  });

  // Debounce the sound effect for StrictMode
  const debouncedPlayAudio = debounce(() => audio.play(), 0);

  const setVolume = (volume: number) => {
    const clampedVolume = clamp(volume, 0, 1);
    audio.volume(clampedVolume);
  };

  const setIsMute = (isMute: boolean) => {
    audio.mute(isMute);
  };

  const stopPlaying = () => {
    audio.stop();
  };

  return {
    play: debouncedPlayAudio,
    setVolume,
    setIsMute,
    stopPlaying,
  };
}
