import { getDefaultStore } from 'jotai';
import { Audio, AudioOptions, createAudio, playAudio } from '../audio';
import soundEffectsUrls, { SoundEffectsName } from './soundEffectsUrls';
import { isSoundEffectsMuteAtom, soundEffectsVolumeAtom } from '@/store/store';
import { getRoomServerApiRoot } from '@/utils';

// Cache the sound effects so that we create them only once.
const soundEffects: Partial<Record<SoundEffectsName, Audio>> = {};

// Play a sound effect by name with a specified volume.
export function playSoundEffect(
  soundName: SoundEffectsName,
  options?: AudioOptions
): Audio | undefined {
  return playAudio(
    soundEffects,
    soundEffectsUrls,
    soundEffectsVolumeAtom,
    isSoundEffectsMuteAtom,
    soundName,
    options
  );
}

// Play a sound effect by name with a specified volume.
function playAudioFromUrl(url: string): Audio | undefined {
  const { get } = getDefaultStore();
  const volume = get(soundEffectsVolumeAtom);
  const isMute = get(isSoundEffectsMuteAtom);

  const audio = createAudio(url, { format: ['mp3'] });
  audio.setVolume(volume);
  audio.setIsMute(isMute);
  audio.play();
  return audio;
}

export function playAudioFromKey(
  audioKey: string | undefined
): Audio | undefined {
  if (!audioKey) return;
  const audioUrl = `${getRoomServerApiRoot()}/audio/${audioKey}`;
  return playAudioFromUrl(audioUrl);
}
