import { GameMetaData } from '../types';
import ThumbnailA from './assets/Thumbnail_Jalapeno.png';

export default {
  name: 'Crimes Against Huge Manatees',
  primaryAccentColor: 'black',
  secondaryAccentColor: 'white',
  thumbnailImage: ThumbnailA,
  taglines: ['Fill-in-the-blank', 'Hilarious'],
  description: '',
  elevatorPitch: 'Play the funniest card to win!',
  minPlayers: 3,
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10 mins',
    },
    {
      emoji: '👥',
      text: '3+ players',
    },
  ],
  howToSlides: [
    {
      text: 'Each round, the judge picks their favorite white card.',
    },
  ],
} satisfies GameMetaData;
