import { ChallengeType, challengeRewards } from '@common/challenges';
import { PollId, PollOptionId } from '@common/poll-metadata';
import { CurrencyGrantType } from '@common/types/currencies';
import {
  ClaimPendingGrantsResponse,
  GetAggregatedPollOptionDataResponse,
  GetCreditsResponse,
  GetPollRewardResponse,
  GetPollVoteResponse,
  GetUnclaimedGrantsResponse,
} from '@common/types/me';
import { DatabaseUser } from '@common/types/user';
import { UserChallenge, UserCosmeticItem, UserStat } from '@prisma/client';
import useSWR from 'swr';
import { useIsUserAuthenticated } from '@/store/store';
import { post, sendRequest } from '@/utils';

// SWR convention is to call the fetcher function `fetcher`
// See: https://swr.vercel.app/docs/data-fetching
const fetcher = sendRequest;

export function useAuthenticatedResource<JSON>(params: {
  path: string;
  json?: unknown;
  refreshInterval?: number;
}) {
  const { path, json, refreshInterval = 60 * 1000 } = params;
  const isUserAuthenticated = useIsUserAuthenticated();
  // If the user is not authenticated, return null
  // This is a "conditional fetch", which is a way to tell SWR to only fetch
  // the resource if a certain condition is met.
  const pathWithAuth = isUserAuthenticated ? path : null;
  return useSWR<JSON, string>(
    pathWithAuth,
    json ? () => fetcher(path, json) : fetcher,
    {
      refreshInterval,
    }
  );
}

// When the user is serialized to JSON, the createdAt and lastActiveAt fields
// are serialized as date strings, not Date objects.
type UserWithStringDates = Omit<DatabaseUser, 'createdAt' | 'lastActiveAt'> & {
  createdAt: string;
  lastActiveAt: string;
};

export const useUser = () => {
  const { data, error, isLoading, mutate } = useAuthenticatedResource<
    UserWithStringDates | undefined
  >({ path: '/me' });
  return { user: data, error, isLoading, mutateUser: mutate };
};

export const useCurrencyBalance = () => {
  const { user } = useUser();
  return user?.currencyBalance ?? 0;
};

export const useCreditsBalance = () => {
  const { data, mutate } = useAuthenticatedResource<GetCreditsResponse>({
    path: '/me/credits',
  });
  return {
    availableCredits: data?.availableCredits ?? 0,
    replenishAt: data?.replenishAt ? new Date(data.replenishAt) : null,
    mutateCreditsBalance: mutate,
  };
};

export const useMyUserStats = () => {
  const { data, error, isLoading, mutate } = useAuthenticatedResource<
    UserStat[]
  >({ path: '/me/stats' });
  return { stats: data ?? [], error, isLoading, mutate };
};

export const useUnclaimedGrantsAmount = (types?: CurrencyGrantType[]) => {
  const { data, error, isLoading, mutate } =
    useAuthenticatedResource<GetUnclaimedGrantsResponse>({
      path: '/me/grants',
      json: types ? { types } : undefined,
    });
  return {
    totalAmountUnclaimed: data?.totalAmountUnclaimed ?? 0,
    error,
    isLoading,
    mutateTotalAmountUnclaimed: async (totalAmountUnclaimed: number) => {
      await mutate({ totalAmountUnclaimed });
    },
  };
};

export function useMe_UserCosmeticItems() {
  const { data, error, isLoading, mutate } = useAuthenticatedResource<
    UserCosmeticItem[]
  >({
    path: '/me/cosmetics',
  });

  return {
    myCosmeticItems: data ?? [],
    error,
    isLoading,
    mutate,
  };
}

export async function claimPendingGrants(grantTypes?: CurrencyGrantType[]) {
  return await post<ClaimPendingGrantsResponse>(
    '/me/grants/claim',
    grantTypes ? { types: grantTypes } : undefined
  );
}

/**
 * Returns a function that increases the current user's currencyBalance by the
 * specified amount. This function is intended to be passed to SWR's mutate as
 * optimisticData.
 *
 * @param {number} amount - The amount by which to increase the currency
 * balance.
 */
function optimisticallyIncreaseCurrencyBalance(amount: number) {
  return (
    _currentData: UserWithStringDates | undefined,
    displayedData: UserWithStringDates | undefined
  ) => {
    if (!displayedData) return displayedData;
    return {
      ...displayedData,
      currencyBalance: displayedData.currencyBalance + amount,
    };
  };
}

/**
 * Hook that returns a function to optimistically complete a user challenge.
 * This function will update the user's currency balance optimistically and then
 * send a request to complete the challenge on the server.
 *
 * @returns A function that takes a UserChallenge object and completes the challenge optimistically.
 */
export function useCompleteChallengeOptimistically() {
  const mutateUser = useUser().mutateUser;

  const completeChallengeOptimistically = async (challenge: UserChallenge) => {
    async function completeChallenge() {
      await post(`/me/challenges/${challenge.id}/complete`);
      return undefined;
    }

    const rewardAmount = challengeRewards[challenge.type as ChallengeType];

    await mutateUser(completeChallenge, {
      optimisticData: optimisticallyIncreaseCurrencyBalance(rewardAmount),
      populateCache: false,
      revalidate: true,
    });
  };

  return completeChallengeOptimistically;
}

export function usePollVote(pollId: PollId) {
  const { data, error, isLoading, mutate } =
    useAuthenticatedResource<GetPollVoteResponse>({
      path: `/me/polls/${pollId}/vote`,
      refreshInterval: 0,
    });
  return {
    vote: data?.pollVote ?? undefined,
    error,
    isLoading,
    mutate,
  };
}

export function useAggregatedPollOptionData(pollId: PollId) {
  const { data, error, isLoading, mutate } =
    useAuthenticatedResource<GetAggregatedPollOptionDataResponse>({
      path: `/me/polls/${pollId}/options`,
      refreshInterval: 0,
    });
  return {
    aggregatedOptionData: data?.pollOptions ?? undefined,
    error,
    isLoading,
    mutate,
  };
}

export async function voteInPoll(pollId: PollId, pollOptionId: PollOptionId) {
  return await post(`/me/polls/${pollId}/vote`, {
    pollOptionId,
  });
}

export async function deletePollVote(pollId: PollId) {
  return await sendRequest(`/me/polls/${pollId}/vote`, null, {
    method: 'DELETE',
  });
}

export function usePollReward(pollId: PollId) {
  const { data, error, isLoading, mutate } =
    useAuthenticatedResource<GetPollRewardResponse>({
      path: `/me/polls/${pollId}/reward`,
      refreshInterval: 0,
    });
  return {
    reward: data?.pollReward ?? undefined,
    error,
    isLoading,
    mutate,
  };
}
export async function claimPollReward(pollId: PollId) {
  return await post(`/me/polls/${pollId}/reward`);
}

export async function claimGrant(grantId: number) {
  return await post(`/me/grants/${grantId}/claim`);
}
